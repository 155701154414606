import * as React from "react"
import Layout from "./layout"
import ServiceDetail from "../components/service-detail"
const SpaTreatmentPage = () => {
    const style = {
        textAlign: "center"
    };
    const details = [
        {
            id: "bodyTreatment",
            title: "BODY TREATMENT",
            description:"Perfect if you are having skin challenges on your back or for a special occasion, this treatment will focus on skin exfoliation and a back massage.",
            imagePath: {
                src1: "/wp-content/uploads/2016/10/women-facial-face-catalog.jpg",
                src2: "/wp-content/uploads/2016/10/Athena-Women-Refresh.png",
                src3: "/wp-content/uploads/2016/10/Athena-Women-Spa.png",
                src4: "/wp-content/uploads/2016/10/lady-face-smile-300px.jpg",
            },
            services: [
                "Relaxing Massage",
                "Mud Wrap", 
                "Hot Stone Massage",
                "Back Facial", 
                "AFA Peel",
                "Microdermabrasion"
            ]
        }, {
            id: "manicure",
            title: "MANICURE & PEDICURE",
            description:"Not just a manicure or pedicure, this is hand and foot thearpy. A necessary spa experience to bring your hands and feet back to balance.",
            imagePath: {
                src1: "/wp-content/uploads/2016/10/manicure-catalog.jpg",
                src2: "/wp-content/uploads/2016/10/manicure-feet-catalog.jpg",
                src3: "/wp-content/uploads/2016/10/manicure-hand-nail-catalog.jpg",
                src4: "/wp-content/uploads/2016/10/manicure-nails-catalog.jpg",
            },
            services: [
                "Manicure",
                "French Manicure",
                "Nail Polish Change"
            ]
        }];

    let serviceList = [];

    details.forEach((detail, index) => {
        serviceList.push(<ServiceDetail detail={detail} key={index} />);
    });

    return (

        <Layout>
            <div className="container">
            <div className="dt-sc-hr-invisible-small  "></div>
                <h1 className="hr-title dt-page-title">
                    <span>SPA &amp;TREATMENT</span>
                </h1>
                <div className="dt-sc-hr-invisible-small  "></div>
                <div className="dt-sc-clear"></div>
                <section id="primary" className="content-full-width">
                    <div id="post-26" className="post-26 page type-page status-publish hentry">
                        <p style={style}>
                            <a href="#bodyTreatment" target="_self" className="dt-sc-button medium ">Body Treatment</a>&nbsp;
                            <a href="#manicure" target="_self" className="dt-sc-button     medium ">Manicure & Pedicure</a>&nbsp;
                        </p>
                        {serviceList}
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SpaTreatmentPage
